$(function(){
    $(".social-share button").click(function(e){

        var url = '';
        var height = $(this).data('height');
        var width = $(this).data('width');
        var permalink = $(this).closest('ul').data('permalink');
        var title = $(this).closest('ul').data('title');

        if(permalink.indexOf('http')==-1){
            permalink = 'https://' + window.location.hostname + permalink;
        }

        console.log(permalink);


        if($(this).hasClass("fa-pinterest")){
            return false;
        }

        if($(this).hasClass("email")){
            return;
        }

        if($(this).hasClass("fa-twitter")){
            // Share via Twitter.
            url = "https://twitter.com/intent/tweet?text="+title+"&url="+permalink;
        }

        if($(this).hasClass("fa-facebook-f")){
            url = "https://www.facebook.com/sharer.php?u="+permalink+"&t="+title;
        }

        if($(this).hasClass("fa-linkedin-in")){
            url = "https://www.linkedin.com/shareArticle?mini=true&url="+permalink+"&title="+title;
        }

        if($(this).hasClass("fa-google-plus")){
            url = "https://plus.google.com/share?url="+permalink+"&hl=en-US";
        }

        window.open(url, 'social-share', 'width='+width+',height='+height+'');
    });
})