$(".open-video-modal").click(function(e){
    e.preventDefault();
    $(".video-modal").addClass("open");
    $(".video-modal-overlay").addClass("open");
    $(".video-modal iframe")[0].src = $(".video-modal iframe").attr("data-src");
});

$(".close-video-modal").click(function(){
    $(".video-modal").removeClass("open");
    $(".video-modal-overlay").removeClass("open");
    $(".video-modal iframe")[0].src = "";
});