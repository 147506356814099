/* Get HTML5 tags working in IE 8. */
document.createElement('header');
document.createElement('nav');
document.createElement('article');
document.createElement('aside');
document.createElement('footer');
document.createElement('section');
jQuery(document).ready(function($) {
    // Open external links in new window. Also adds accessibility tags, and PDF Download option.
    var my_dom = window.location.hostname;
    if (my_dom.indexOf("www.") == 0) {
        my_dom = my_dom.substr(4);
    }
    $("a[href^='http://'],a[href^='https://']").each(function(){
        var this_dom = this.hostname;
        if (this_dom.indexOf("www.") == 0) {
            this_dom = this_dom.substr(4);
        }
        if (this_dom != my_dom) {
            $(this).attr("target", "_blank");
            if($(this).find(".sr-only").length==0){
                $(this).append(' <span class="sr-only">Opens in a new window</span>');
            }
            if(!$(this)[0].hasAttribute('rel')){
                $(this).attr('rel', 'noopener');
            }
        }
        if($(this).attr('href').indexOf('.pdf')!=-1){
            if($('.get-pdf-link').length==0){
                $(this).append('<a href="https://get.adobe.com/uk/reader/" class="get-pdf-link"><span class="sr-only">Download acrobat reader</span></a>');
            }
        }
    });

    var altCheck = setInterval(function() {
        var count = 0
        $("img").each(function () {
            if (typeof $(this).attr('alt') === 'undefined') {
                $(this).attr("alt", 'layout');
                count ++;
            }
        });

        if(count==0){
            clearInterval(altCheck);
        }

    }, 2000);
});