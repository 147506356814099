$(function() {
    $(".enquiry-form form").submit(function (e) {
        $(".enquiry-form form .message span").html("");
        $(".enquiry-form form .message").removeClass("open");
        $(".enquiry-form form .message").removeClass("success");
        $(".enquiry-form form .message").removeClass("error");
        $(".enquiry-form form input[type='submit']").val('Sending');

        $(".enquiry-form form .form-submit").attr("disabled", "disabled");

        var url = $(".enquiry-form form").attr("action");

        $.ajax({
            type: "POST",
            url: url,
            data: $(".enquiry-form form").serialize(),
            success: function (formreturn) {
                errors = '';
                message = '';

                console.log(formreturn);

                status = formreturn.response.status;

                if (status == "fail") {
                    errors = formreturn.response.data.errors;
                    console.log(errors);

                    message = "<ul>";

                    $(errors).each(function (index) {
                        console.log(index);
                        message += "<li>" + this.message + "</li>";
                    });

                    message += "</ul>";

                    $(".enquiry-form form .message span").html(message);
                    $(".enquiry-form form .message").addClass("open");
                    $(".enquiry-form form .message").addClass("error");
                    $(".enquiry-form form .form-submit").removeAttr("disabled");
                    $(".enquiry-form form input[type='submit']").val('Re-send');

                }
                else if (status == "success") {
                    $(".enquiry-form form .message span").html("Thank you for your enquiry. We will respond to you within 24 hours.");
                    $(".enquiry-form form .message").addClass("open");
                    $(".enquiry-form form .message").addClass("success");
                    $(".enquiry-form form input[type='submit']").val('Sent');

                    $('.enquiry-form form').find("input[type=text], input[type=email], textarea").val("");

                }
            },
            error: function(){
                $(".enquiry-form form .message span").html("Something went wrong");
                $(".enquiry-form form .message").addClass("open");
                $(".enquiry-form form .message").addClass("error");
                $(".enquiry-form form .form-submit").removeAttr("disabled");
                $(".enquiry-form form input[type='submit']").val('Re-send');
            }
        });

        e.preventDefault();
    });
});
