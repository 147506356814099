$(function () {

    $(".company-logo").on("click", function () {
        var id = $(this).attr("data-id");
        var company = $("#company-" + id);

        $('html,body').animate({
            scrollTop: company.offset().top - 150
        }, 800);

    });


});