$(function(){

   $(".slider").bxSlider({
       mode:'fade',
       auto: true,
       autoHover: false,
       pause: 5000,
       pager: false,
       controls: false,
       touchEnabled:false,
       stopAutoOnClick:true
   });

   $(".scroll-down").click(function(){
       var scrollTo = $(".home section").first().offset().top;
       $("html, body").animate({
            scrollTop: (scrollTo-50)
       })
   })


});