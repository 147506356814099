
$(function(){

    function changeHeaderStyle(){

        var showAlt = false;
        var target = $(".home-slider > li, .featured-image").first().height();
        var headerHeight = 96;//$("header.site-header").height();
        var scrollPosition = $(window).scrollTop();

        if($('.featured-image').hasClass("no-image")){
            showAlt = true;
        }

        if( ( ( target-headerHeight ) < scrollPosition ) || showAlt ){
            $("header.site-header").addClass('alt');
        }else{
            $("header.site-header").removeClass('alt');
        }
    }

    changeHeaderStyle();

    $(window).on('scroll', function(){
        changeHeaderStyle();
    });

    $('.menu-trigger').click(function() {
        $('.main-menu').slideToggle();
        $(this).toggleClass("is-active");
    });

    if ($(this).width() < 990) {
        $('li.has-children').click(function(e) {
            if($(this).hasClass("open")){

            } else {
                e.preventDefault();
                $(this).addClass("open");
            }
        });
    }

    $(window).resize(function() {
        if ($(this).width() < 990) {
            $('li.has-children').click(function(e) {
                if($(this).hasClass("open")){

                } else {
                    e.preventDefault();
                    $(this).addClass("open");
                }
            });
        }
    });

    $(window).resize(function() {
        if ($(this).width() > 990) {
            $('header nav').removeAttr('style');
        }
    });

});