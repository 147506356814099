$(function(){
    if(window.location.hash && window.location.hash == "#surveys") {

        setTimeout(function() {
            $('html, body').stop().animate({
                'scrollTop': $(".surveys-block").offset().top - 100
            }, 900, 'swing');
        }, 1000);

    } else {

    }

    $(".scrollToNews").click(function(){
        $('html, body').stop().animate({
            'scrollTop': $("#newsBlock").offset().top - 100
        }, 900, 'swing');
    });

    $(".scrollToSurveys").click(function(){
        $('html, body').stop().animate({
            'scrollTop': $("#surveysBlock").offset().top - 100
        }, 900, 'swing');
    });


});